.instalogin {

    color: #3c4043;
    font-size: "14px !important";
    font-family:  "Google Sans",arial,sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #dadce0;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 100%;
    width: 100%;

  }

  .instalogin:hover {
    background-color: #f7fafe;
    border-color:#d2e3fc;
  }

  .instaicon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
   color: linear-gradient(to right, rgb(236, 146, 35) 0%, rgb(177, 42, 160) 50%, rgb(105, 14, 224) 100%)
  }

